import React from 'react';
import { navigate } from 'gatsby';
// import { isLoggedIn } from '../utils/auth';

export type PrivateRouteProps = {
    component: any;
    path: string;
    location?: any;
    isRequired?: boolean;
};

const isLoggedIn = () => true;

const PrivateRoute = ({ component: Component, location, ...rest }: PrivateRouteProps) => {
    console.log('PrivateRoute', isLoggedIn(), location.pathname);
    if (!isLoggedIn() && location.pathname !== '/app/login') {
        // If we’re not logged in, redirect to the home page.
        navigate('/app/login');
        return null;
    }
    return <Component {...rest} />;
};

export default PrivateRoute;
