import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layouts/Layout';
import PrivateRoute from '../components/PrivateRoute';
import Stations from '../components/pages/StationListPage';
import Station from '../components/pages/StationPage';

const App = (): React.ReactElement => (
    <Layout>
        <Router basepath="/">
            <PrivateRoute path="/" component={Stations} />
            <PrivateRoute path="/stations" component={Stations} />
            <PrivateRoute path="/stations/:stationId" component={Station} />
        </Router>
    </Layout>
);

export default App;
